import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Link } from 'gatsby'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const Overview = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const data = [
          {
               title: 'Marketing Leaders',
               content: 'Connect your analytics and marketing data to revenue and pipeline.',
               to: '/outcomes/marketing-leaders/',
          },
          {
               title: 'Marketing Specialist',
               content: 'Take control of your marketing data wthout becoming an expert.',
               to: '/outcomes/marketing-specialists/',
          },
          {
               title: 'Measurement Professionals',
               content: 'Implement faster, manage easier, and deliver better insights.',
               to: '/outcomes/measurement-pros/',
          },
          {
               title: 'Engineering, Developers, & IT',
               content: "Fulfill marketing's data needs with a secure platform that doesn't require IT resources.",
               to: '/outcomes/engineers-and-it/',
          },
          {
               title: 'B2B Marketers',
               content: 'Connect marketing investment to pipeline and revenue. ',
               to: '/outcomes/b2b-marketing-analytics/',
          },
          {
               title: 'B2C Marketers',
               content: 'Advanced eCommerce & lead generation data in one place.',
               to: '/outcomes/b2c-marketing-analytics/',
          },
          {
               title: 'Marketing Agencies',
               content: 'Prove results while generating unique data and better insights for your clients. ',
               to: '/outcomes/agencies/',
          },
          {
               title: 'Healthcare',
               content: 'Lorem ipsum',
               to: '/outcomes/hipaa-marketing-data/',
          },
     ]

     const pageName = 'Overview'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="custom-centralized-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro custom-overview">
                    <div className="container">
                         <div className="platform-intro-title mt-0">
                              <h1>Drive Better Marketing Outcomes</h1>
                              <b className="platform-intro-str">Without Dedicating Massive Resources to Manual Reporting</b>
                              <p className="mt-3">
                                   ListenLayer is used across the marketing organization to save time, collect better data, remain compliant, and tie
                                   activity to results.
                              </p>
                              <p>Select a role explore the benefits.</p>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step custom-box-card-step mb-0">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-6 mb-6" style={{ marginBottom: '16px' }}>
                                                  <div className="card-step">
                                                       <div className="card-body mb-0 d-flex flex-column h-100">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-desc" style={{ flex: '1' }}>
                                                                 {item.content}
                                                            </p>
                                                            <div className={`button-wrapper mt-4`}>
                                                                 <Link className="btn button" to={item.to}>
                                                                      {item.to ? 'LEARN MORE' : 'COMING SOON'}
                                                                 </Link>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default Overview
